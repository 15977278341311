import request from '@/utils/request';

import storage from "../storage/storage.js";

const historyRecord = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/historyRecord/list',
        method: 'get',
        params: {
            hospitalId: users && users.hospitalId || "",
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.current,
            keywords: data.name,
            startTime: data.date && data.date[0] ? data.date[0] : '',
            endTime: data.date && data.date[1] ? data.date[1] : '',
        }
    })
}

const downloadPatientHistory = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/historyRecord/export',
        method: 'post',
        responseType: "blob",
        params: {
            hospitalId: users && users.hospitalId || "",
            keywords: data.keywords,
            startTime: data && data.date != null ? data.date[0] : undefined,
            endTime: data && data.date != null ? data.date[1] : undefined,
        }
    })
}

export default {
    historyRecord,
    downloadPatientHistory
}