let jurisdictionList = [
    {
        title: '医护管理',
        key: 'healthcare',
    },
    {
        title: '医护管理-查看列表',
        key: 'healthcare-checkList'
    }, {
        title: '医护管理-查看列表',
        key: 'healthcare-CheckList'
    },
    {
        title: "患者管理",
        key: "patientmanagement",
    },
    // 患者管理子目录
    {
        title: '患者信息记录',
        key: 'patientinformation',
    },
    {
        title: '患者信息记录-查看列表',
        key: 'patientinformation-checkList'
    }, {
        title: '患者信息记录-查看统计数据',
        key: 'patientinformation-statisticsBtn',
    }, {
        title: '患者信息记录-下载数据',
        key: 'patientinformation-downloadBtn'
    },
    {
        title: '患者历史操作记录',
        key: 'patientinoperation',
    }, {
        title: '患者历史操作记录-查看列表',
        key: 'patientinoperation-checkList',
    }, {
        title: '患者历史操作记录-数据下载',
        key: 'patientinoperation-downloadBtn',
    },
    {
        title: '患者静态信息',
        key: 'patientstaticinformation',
    }, {
        title: '患者历史操作记录-查看列表',
        key: 'patientstaticinformation-checkList',
    }, {
        title: '患者历史操作记录-数据下载',
        key: 'patientstaticinformation-downloadBtn',
    },
    // 患者管理子目录 end
    {
        title: "医院管理",
        key: "hospitalmanagement",
    }, {
        title: "医院管理-新增",
        key: "hospitalmanagement-addBtn"
    },
    {
        title: "医院管理-编辑",
        key: "hospitalmanagement-compileBtn"
    },
    {
        title: "医院管理-重置密码",
        key: "hospitalmanagement-resetPassword"
    },
    {
        title: "数据分析",
        key: "dataanalysis",
    },
    // 数据分析子目录
    {
        title: "血糖质量控制",
        key: "bloodglucosequality",
    },
    {
        title: "血糖质量控制-查看列表",
        key: "bloodglucosequality-checkList",
    },
    {
        title: "医护行为分析",
        key: "behavioranalysis",
    },
    {
        title: "医护行为分析-查看列表",
        key: "behavioranalysis-checkList",
    },
    // 数据分析子目录 end
    {
        title: "系统设置",
        key: "systemSettings",
    },
    {
        title: '账号管理',
        key: 'accountManagement',
    },
    {
        title: '账号管理-查看列表',
        key: 'accountManagement-checkList',
    },
    {
        title: '账号管理-新增',
        key: 'accountManagement-addBtn'
    }, {
        title: '账号管理-编辑',
        key: 'accountManagement-compileBtn'
    }, {
        title: '账号管理-删除',
        key: 'accountManagement-deleteBtn'
    },
    {
        title: '权限管理',
        key: 'authorityManagement',
    },
    {
        title: '权限管理-查看列表',
        key: 'authorityManagement-checkList',
    },
    {
        title: '权限管理-新增',
        key: 'authorityManagement-addBtn',
    }, {
        title: '权限管理-编辑',
        key: 'authorityManagement-compileBtn',
    }, {
        title: '权限管理-查看',
        key: 'authorityManagement-examineBtn',
    }, {
        title: '权限管理-删除',
        key: 'authorityManagement-deleteBtn',
    },
];

export default jurisdictionList