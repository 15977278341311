import userApi from './user'
import Patientinformation from './Patientinformation'
import sickinformation from './sickinformation'
import healthcare from './Healthcare'
import AccountManagement from './AccountManagement'
import AuthorityManagement from './AuthorityManagement'
import Patientinoperation from './Patientinoperation'
import patientstaticinformation from './patientstaticinformation'
import Bloodglucosequality from './Bloodglucosequality'
import Hospital from './Hospital'
import behavioranalysis from './behavioranalysis'

export default Object.assign({}, userApi, Patientinformation, sickinformation, healthcare, AccountManagement, AuthorityManagement, Patientinoperation, patientstaticinformation, Bloodglucosequality, Hospital,behavioranalysis)