import request from '@/utils/request';

import storage from "../storage/storage.js";

const addAuthority = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/management',
        method: 'post',
        data: {
            name: data.name,
            accessList: data.accessList,
            hospitalId: users && users.hospitalId || "",
        }
    })
}

// 查询数据
const getAuthority = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/management/list',
        method: 'get',
        params: {
            name: data.name,
            hospitalId: users && users.hospitalId || "",
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.current
        }
    })
}

// 修改数据
const amendAuthority = (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/management',
        method: 'put',
        data: {
            accessId: data.accessId,
            name: data.name,
            accessList: data.accessList,
            hospitalId: users && users.hospitalId || "",
        }
    })
}

// 删除列表
const deleteAuthority = async (data) => {
    return request({
        url: '/system/management/' + data.accessId,
        method: 'delete',
    })
}

export default {
    addAuthority,
    getAuthority,
    amendAuthority,
    deleteAuthority
}