var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("CMS登录系统")]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"账号:"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      'username',
      {
        rules: [{ required: true, message: '请输入您的账号' }],
      } ]),expression:"[\n      'username',\n      {\n        rules: [{ required: true, message: '请输入您的账号' }],\n      },\n    ]"}],attrs:{"type":"text","placeholder":"请输入账号"}})],1),_c('a-form-item',{attrs:{"label":"密码:"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      'password',
      {
        rules: [{ required: true, message: '请输入您的密码' }],
      } ]),expression:"[\n      'password',\n      {\n        rules: [{ required: true, message: '请输入您的密码' }],\n      },\n    ]"}],attrs:{"type":"password","placeholder":"请输入密码"}})],1),_c('a-checkbox',{on:{"change":_vm.checkedps},model:{value:(_vm.ischecked),callback:function ($$v) {_vm.ischecked=$$v},expression:"ischecked"}},[_vm._v("记住密码")]),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }