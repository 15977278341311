import Vue from 'vue';
import Router from 'vue-router';
import Login from '../components/login/login.vue'
import Header from '../components/header/header.vue'
import storage from '../storage/storage.js';
Vue.use(Router)

//解决路由重复点击报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

let router = new Router({
    routes: [{
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },

    // {
    //     path: '/home',
    //     name: 'home',
    //     component: home,
    //     redirect: {
    //         name: ''
    //     },
    //     meta: {
    //         title: '',
    //         requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的

    //     },
    //     // children: [{
    //     //         path: '/healthcare',
    //     //         name: 'healthcare',
    //     //         component: healthcare,
    //     //         // component: resolve => require(['../components/healthcare/healthcare.vue'], resolve),
    //     //         meta: {
    //     //             title: '医护管理',
    //     //             requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的

    //     //         }
    //     //     }, {
    //     //         path: '/patientinformation',
    //     //         name: 'patientinformation',
    //     //         component: patientinformation,
    //     //         meta: {
    //     //             title: '患者信息记录',
    //     //             requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    //     //         }
    //     //     },
    //     //     // {
    //     //     //     path: '/sickinformation',
    //     //     //     name: 'sickinformation',
    //     //     //     component: sickinformation,
    //     //     //     meta: {
    //     //     //         title: "患者信息记录数据统计",
    //     //     //         requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    //     //     //     }
    //     //     // },
    //     //     {
    //     //         path: '/accountmanagement',
    //     //         name: 'accountmanagement',
    //     //         component: accountManagement,
    //     //         meta: {
    //     //             title: "账号管理",
    //     //             requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    //     //         }
    //     //     },
    //     //     {
    //     //         path: '/authoritymanagement',
    //     //         name: 'authoritymanagement',
    //     //         component: authorityManagement,
    //     //         meta: {
    //     //             title: "权限管理",
    //     //             requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    //     //         }
    //     //     }
    //     // ]
    // },
    {
        path: '/header',
        name: 'header',
        component: Header
    },
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
        if (storage.getItem('account')) { // 判断缓存里面是否有 userName  //在登录的时候设置它的值
            if (to.path == '/' && (to.path != from.path)) {
                next(from.path)
            } else {
                next();
            }
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    } else {
        if (to.path == '/' && (to.path != from.path) && storage.getItem('account')) {
            next(from.path)
        } else {
            next();
        }
    }

    next();

});

export default router