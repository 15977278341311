import request from '@/utils/request';

import storage from "../storage/storage.js";

const patientDownload = async (data) => {
    return request({
        url: '/system/patient/export',
        method: 'post',
        responseType: "blob",
        params: {
            recordNumber: data.name,
        }
    })
}

export default {
    patientDownload
}