import request from '@/utils/request';

import storage from "../storage/storage.js";

const patient = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/patient/list',
        method: 'get',
        params: {
            hospitalId: data.hospitalId || users && users.hospitalId || "",
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.current,
            recordNumber: data.name
        }
    })
}

const exportPatient = (data) => {
    return request({
        url: '/system/patient/export',
        method: 'post',
        responseType: "blob",
        params: {
            glsRecordIds: data.glsRecordIds
        }
    })
}

export default {
    patient,
    exportPatient
}