import request from '@/utils/request';

// 医嘱执行率排名
const getHospitalExecutionRanking = async (data) => {
    return request({
        url: '/system/executionRanking/list',
        method: 'get',
        params: {
            hospitalId: data.hospitalId,
        }
    })
}

// 医嘱执行率
const getHospitalExecutionRate = async (data) => {
    return request({
        url: '/system/executionRate/list',
        method: 'get',
        params: {
            hostpitalId: data.hospitalId,
            startTime: data.startTime,
            endTime: data.endTime,
        }
    })
}

// 采纳率
const getHospitalAdoptRate = async (data) => {
    return request({
        url: '/system/adoptRate/list',
        method: 'get',
        params: {
            hostpitalId: data.hospitalId,
            startTime: data.startTime,
            endTime: data.endTime,
        }
    })
}

export default {
    getHospitalExecutionRanking,
    getHospitalExecutionRate,
    getHospitalAdoptRate
}