import request from '@/utils/request';

import storage from "../storage/storage.js";

const addAccount = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/back/user',
        method: 'post',
        data: {
            hospitalId: users && users.hospitalId || "",
            accessId: data.accessId, //身份的id
            name: data.name, //姓名
            userName: data.userName, //账号名称
            phonenumber: data.phonenumber, //手机号码
            roleName: users && users.hospitalId ? 'hospital' : "admin", //角色名称
            password: data.password //账号密码
        }
    })
}

// 医护列表
const getAccount = async (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/back/user/list',
        method: 'get',
        params: {
            hospitalId: users && users.hospitalId || "",
            userName: data.userName,
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.current
        }
    })
}

// 修改数据
const amendAccount = async (data) => {
    return request({
        url: '/system/back/user',
        method: 'put',
        data: {
            userObjectId: data.userObjectId,
            accessId: data.accessId, //身份的id
            name: data.name, //姓名
            userName: data.userName, //账号名称
            phonenumber: data.phonenumber, //手机号码
        }
    })
}

// 删除列表
const deleteAccount = async (data) => {
    return request({
        url: '/system/back/user/' + data.userObjectId,
        method: 'delete',
    })
}

export default {
    addAccount,
    getAccount,
    amendAccount,
    deleteAccount
}