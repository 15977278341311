import request from '@/utils/request';

// 获取列表
const getHospitalList = async (data) => {
    return request({
        url: '/system/hospital/list',
        method: 'get',
        params: data
    })
}

// 创建医院
const createHospital = async (data) => {
    return request({
        url: '/system/hospital',
        method: 'post',
        data
    })
}

// 编辑医院
const updateHospital = async (data) => {
    return request({
        url: '/system/hospital',
        method: 'put',
        data
    })
}

// 医院状态（删除）
const updatedHospitalStatus = async (data) => {
    return request({
        url: '/system/hospital/' + data.id,
        method: 'delete',
    })
}

// 重置密码
const updateHospitalPassword = async (data) => {
    return request({
        url: '/system/hospital/updatePassword',
        method: 'put',
        data
    })
}

export default {
    getHospitalList,
    createHospital,
    updateHospital,
    updateHospitalPassword,
    updatedHospitalStatus
}