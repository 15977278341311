import request from '@/utils/request';

// 登录
const login = (userInfo) => {
    return request({
        url: '/auth/login',
        method: 'post',
        data: {
            username: userInfo.username,
            password: userInfo.password,
            clientId: "e5cd7e4891bf95d1d19206ce24a7b32e",
            grantType: "password"
        },
    });
}

// 获取登录用户信息
const getUserInfo = () => {
    return request({
        url: '/system/user/getInfo',
        method: 'get',
    })
}

const logout = () => {
    return request({
        url: '/auth/logout',
        method: 'post',
    })
}

//获取医院（下拉）
const getHospitals = async (data) => {
    return request({
        url: '/system/hospital/list',
        method: 'get',
        params: {
            status: 0 //0正常 1停用
        }
    })
}

export default {
    login,
    getHospitals,
    logout,
    getUserInfo
}