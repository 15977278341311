import request from '@/utils/request';

import storage from "../storage/storage.js";

// 查看统计信息（查询患者血糖记录列表）
const listGlsRecord = (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/glsRecord/list',
        method: 'get',
        params: {
            hospitalId: users && users.hospitalId || "",
            patientId: data.patientId,
            startTime: data.startTime,
            endTime: data.endTime,
        }
    })
}

const exportGlsRecord = (data) => {
    return request({
        url: '/system/glsRecord/export',
        method: 'post',
        responseType: "blob",
        params: {
            patientIds: data.patientIds
        }
    })
}

export default {
    listGlsRecord,
    exportGlsRecord
}