import request from '@/utils/request';

const getPatientsGlsMedian = async (data) => {
    return request({
        url: '/system/getPatientsGlsMedian',
        method: 'get',
        params: data
    })
}

const getPatientsTirAndCvRecordMedian = async (data) => {
    return request({
        url: '/system/getPatientsTirAndCvRecordMedian',
        method: 'get',
        params: data
    })
}

const getMainDiagnosis = async (data) => {
    return request({
        url: '/system/mainDiagnosis/list',
        method: 'get',
        params: data
    })
}

const getPatientTirAndCvRecordMedian = async (data) => {
    return request({
        url: '/system/getPatientTirAndCvRecordMedian',
        method: 'get',
        params: data
    })
}

export default {
    getPatientsGlsMedian,
    getPatientsTirAndCvRecordMedian,
    getMainDiagnosis,
    getPatientTirAndCvRecordMedian
}