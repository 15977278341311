<template>
<div>
  <a-spin :spinning=" !loading ? loadings : loading">
  <a-Config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-Config-provider>
  </a-spin>
</div>

</template>

<script>
import storage from './storage/storage';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'; //Ant 中文插件
export default {
  name: 'App',
  components: {},
  data() {
    return {
      loadings:false,
      locale: zhCN,
    };
  },
 computed: {
  loading() {
      return this.$store.getters.loading;
    },
  },
  created() {
    this.getMenus();
  },
  methods: {
    getMenus() {
      if (storage.getItem('getJurisdiction')) {
        let getMenu = storage.getItem('getJurisdiction');
        let jurisdictionRoutes = [];
        let childrens = [];
        getMenu.forEach((item) => {
          jurisdictionRoutes.push(item.split('-')[0]);
        });
        [...new Set(jurisdictionRoutes)].forEach((item) => {
          childrens.push({
            path: '/' + item,
            name: item,
            component: () => import(`./components/${item}/${item}.vue`),
            meta: {
              title: '',
              requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
            },
          });
        });
        let routes = [];
        routes.push({
          path: '/home',
          name: 'home',
          component: () => import('./components/home/home.vue'),
          meta: {
            title: '',
            requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
          },
          children: childrens,
        });
        this.$router.options.routes.push(...routes);
        this.$router.addRoutes(this.$router.options.routes);
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.container{
  height: 100%;
  background: #fff;
  border-radius: 25px;
  padding: 30px;
}
</style>
