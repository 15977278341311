import axios from 'axios'
import { message } from 'ant-design-vue'
import storage from "@/storage/storage.js";


const serve = axios.create({
  baseURL: '/dev-api/',
  withCredentials: true,
  timeout: 60000, // 设置60000秒延时关闭请求
})
// 添加请求拦截器
serve.interceptors.request.use(config => {
  // 在发送请求之前做些什么

  let token = storage.getItem('accessToken')
  if (config.url != "/") {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
      config.headers['Clientid'] = 'e5cd7e4891bf95d1d19206ce24a7b32e'
    }
  }

  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
});

serve.interceptors.response.use((response) => {
  if (response.request.responseType == "blob") {
    return response;
  }
  if (response.data.code == 401) {
    message.warning('当前页面已失效，请重新登录！')
    storage.clear();
    window.location.href = '/admin';
  }
  if (response.data.code == 500) {
    message.warning(response.data.msg);
  }
  // 在这里对响应进行处理
  return response.data;
},
  (error) => {
    if (error.response.status == 403) {
      message.warning('当前页面已失效，请重新登录！')
      storage.clear();
      window.location.href = '/admin';
    }

    // 在这里对请求错误进行处理
    return Promise.reject(error);
  })

export default serve
