import request from '@/utils/request';

import storage from "../storage/storage.js";

// 医护列表
const healthcare = (data) => {
    let users = storage.getItem("users");
    return request({
        url: '/system/app/user/list',
        method: 'get',
        params: {
            pageNum: data.current,
            pageSize: data.pageSize,
            hospitalId: users && users.hospitalId || "",
            realName: data.realName
        }
    })
}

export default {
    healthcare
}